/**
 * File:            Media.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Media component, contains image (either full image or thumbnail) in a box with the name and filetype above.
 */
export function Media({ file, colsize, setOpenedMedia, className = "" }) {
    let img = (
        <img
            src={file ? file["thumbnail"] : ""}
            className="object-fit-contain px-1 h-100 align-middle media-thumbnail card-img-top"
            alt=""
        />
    );
    let colClass = typeof colsize !== "undefined" && colsize !== 0 ? "col-" + colsize : "";

    return (
        <div
            id={file["id"]}
            className={`${colClass} px-0 card mb-1 user-select-none ${className}`}
            onClick={setOpenedMedia}>
            <div className="row ml-1 card-header">
                <div className="col-6">{file ? file["name"].split(".")[0] : ""}</div>
                <div className="col-6 header-type">{file ? file["name"].split(".")[1] : ""}</div>
            </div>
            {img}
        </div>
    );
}
