/**
 * File:            FileManager.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Jan 23, 2024
 *
 * Description:
 * This file manages the gathering and storage of files on the Google Drive.
 */
import { useEffect } from "react";

let access_token = "";

/**
 * Generic class for a file stored in the Mediatheek drive.
 * Contains:
 *  - Folder ID:       The folder from which this media was fetched.
 *  - File ID:      A unique ID generated by google.
 *  - File name:    The name given to the file by the creator.
 *  - Width:        The width (in pixels) of the media.
 *  - Height:       The height (in pixels) of the media.
 *  - Type:         The MIME type of the media, e.g. image/jpeg or video/mp4.
 *  - Thumbnail:    The thumbnail generated by google, for videos this is a still frame in low resolution.
 *  - Content:      The file contents, in base 64.
 */
export class MediatheekFile {
    constructor(folderId, id, name, width, height, type, thumbnail, content) {
        this.folder = folderId;
        this.id = id;
        this.name = name;
        this.width = width;
        this.height = height;
        this.type = type;
        this.thumbnail = thumbnail;
        this.content = content;
    }
}

/**
 * Constructor for the FileManager, this sets the access token generated by auth.js, for further use in the file. It
 * also calls get_user_info to gather user info to display.
 *
 * @param {string} accessToken The access token with which files and user information can be retrieved.
 * @param {function} setUserInfo The function that alters the userInfo state.
 */
export function FileManager({ accessToken, setUserInfo }) {
    access_token = accessToken;
    useEffect(() => {
        get_user_info(setUserInfo);
    }, [accessToken, setUserInfo]);
}

/**
 * Calls the google api to gather information of the user to which the access token belongs.
 *
 * @param {*} setUserInfo
 */
export function get_user_info(setUserInfo) {
    if (!access_token) {
        setUserInfo({});

        return;
    }

    let url = "https://www.googleapis.com/drive/v3/about";
    url += "?access_token=" + access_token;
    url += "&fields=user";

    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Authorization", "Bearer " + access_token);

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                let resp = JSON.parse(xhr.response);
                setUserInfo(resp.user);
            } else {
                setUserInfo({});
            }

            xhr.onerror = () => {
                setUserInfo({});
            };
        };
        xhr.send();
    });
}

/**
 * Returns a Promise that, when resolved, contains a list of files with basic metadata in the folder defined by
 * folderId.
 *
 * @param {string} folderId The ID of the folder in which files should be searched.
 */
export function get_files(folderId) {
    if (!access_token) {
        return error_no_access_code();
    }

    let url = "https://www.googleapis.com/drive/v3/files?";
    url += "q=%27" + folderId + "%27%20in%20parents";

    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Authorization", "Bearer " + access_token);

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                let respJson = JSON.parse(xhr.response);
                resolve(respJson["files"]);
            } else {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            }

            xhr.onerror = () => {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            };
        };

        xhr.send();
    });
}

/**
 * Get all file data of a file defined by fileId and returns a MediatheekFile.
 *
 * @param {string} fileId The ID of the file that should be returned.
 */
export async function get_file_data(fileId) {
    // Get metadata and content separate and wait for both to be gathered before continuing.
    const prm_all = await Promise.all([
        get_file_metadata(
            fileId,
            "parents, id, name, mimeType, thumbnailLink, imageMediaMetadata(width, height), videoMediaMetadata(width, height)"
        ),
        get_content(fileId),
    ]);
    const metadata = JSON.parse(prm_all[0]);
    const content = prm_all[1];

    let thumbnail = content;
    let width, height;
    if (metadata["mimeType"].split("/")[0] === "video") {
        width = metadata["videoMediaMetadata.width"];
        height = metadata["videoMediaMetadata.height"];
        thumbnail = metadata["thumbnailLink"];
    } else {
        // imageMediaMetaData can sometimes supply incorrect dimensions (?) so use this way to get dimensions.
        let img = new Image();
        img.src = thumbnail;
        await img.decode();
        width = img.width;
        height = img.height;
    }

    return new MediatheekFile(
        metadata["parents"][0],
        fileId,
        metadata["name"],
        width,
        height,
        metadata["mimeType"],
        thumbnail,
        content
    );
}

/**
 * Get the metadata of the file defined by fileId. Only returns the fields contained in the string fields, separated by
 * commas. E.g. When requesting only the id, name, and file type, fields would be "id, name, mimeType".
 *
 * @param {string} fileId The ID of the file for which the metadata should be requested.
 * @param {string} fields The specific fiels, separated by commas, that should be requested.
 */
export function get_file_metadata(fileId, fields = "") {
    if (!access_token) {
        // TODO: Create error visualization for unauthorized.
        return error_no_access_code();
    }

    let url = "https://www.googleapis.com/drive/v3/files/" + fileId;
    if (fields !== "") {
        url += "?fields=" + encodeURIComponent(fields);
    }

    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Authorization", "Bearer " + access_token);

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            }

            xhr.onerror = () => {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            };
        };
        xhr.send();
    });
}

/**
 * Get the contents of the file defined by fileId. This is achieved by supplying alt=media to the API request.
 *
 * @param {string} fileId The ID of the file for which the metadata should be requested.
 */
export function get_content(fileId) {
    if (!access_token) {
        // TODO: Create error visualization for unauthorized.
        return error_no_access_code();
    }

    let url = "https://www.googleapis.com/drive/v3/files/" + fileId + "?alt=media";

    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.open("GET", url);
        xhr.setRequestHeader("Authorization", "Bearer " + access_token);

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                // Transforms the blob file into base64 for img DOM element.
                let reader = new FileReader();
                reader.readAsDataURL(xhr.response);
                reader.onloadend = () => {
                    resolve(reader.result);
                };

                reader.onerror = () => {
                    reject({
                        status: 415,
                        statusText: "The provided response could not be transformed to base64.",
                    });
                };
            } else {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            }

            xhr.onerror = () => {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            };
        };
        xhr.send();
    });
}

/**
 * Returns a Promise that instantly rejects to keep consistency with Promise returns in other methods in this file.
 */
function error_no_access_code() {
    // TODO: Create error visualization for unauthorized.
    return new Promise(function (resolve, reject) {
        reject({
            status: 403,
            statusText: "No access token provided, have you authenticated yourself via google?",
        });
    });
}
