/**
 * File:            auth.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Jan 22, 2024
 *
 * Description:
 * This file handles authorization with the Google API.
 */

import { useEffect, useState } from "react";
import { redirect, Navigate } from "react-router-dom";

const SCOPES = "https://www.googleapis.com/auth/drive";

/**
 * AuthHandler component, gets added when redirecting to the /auth route. This updates the access token and access
 * cookie and redirects to the home page.
 *
 * @param {function} setAccessToken Alters the access token state.
 */
export function AuthHandler({ setAccessToken }) {
    const queryParameters = extractParams(window.location.href);
    console.log(queryParameters);
    const at = queryParameters["access_token"];
    const exp = queryParameters["expires_in"];

    useEffect(() => {
        setAccessToken(at);
        setAccessCookie(at, exp);
    });

    return <Navigate to="/" />;
}

/**
 * Extracts the parameters from a google redirect url, which uses # instead of ? to announce the start of parameters.
 
 * @param {string} url The url that has to be analyzed.
 * @returns An Object of key, value pairs of the parameters contained in the url. 
 */
function extractParams(url) {
    let rawParams = decodeURI(url).split("#")[1].split("&");

    let params = {};
    rawParams.forEach((param) => {
        let [key, val] = param.split("=");
        params[key] = val;
    });

    return params;
}

/**
 * Callback function that is called as soon as the page is loaded. Adds a script that prepares a token client that has
 * the capability to request an access token.
 *
 * @param {*} setAccessToken Function that changes the Access Token state.
 */
export function loadCallback(setAccessToken) {
    let cookie = getAccessCookie();
    if (cookie !== "") {
        setAccessToken(cookie);
    }
}

/**
 * Sets the mediatheekAccess cookie to the current access token. This cookie will expire at the time googles access
 * token expires.
 *
 * @param {string} access_token The token which should be stored into the cookies.
 * @param {int} expires The amount of seconds in which the token expires.
 */
function setAccessCookie(access_token, expires) {
    let time = new Date();
    time.setSeconds(time.getSeconds() + expires);

    let dateStr = time.toUTCString();
    document.cookie = "mediatheekAccess=" + access_token + "; expires=" + dateStr + ";path=/";
}

/**
 * Retrieves the mediatheekAccess cookie.
 *
 * @returns The mediatheekAccess cookie, or "" when it is not found.
 */
function getAccessCookie() {
    let decodedCookies = decodeURIComponent(document.cookie).split("mediatheekAccess=");

    // If only 1 element is returned, no mediaTheekAccess cookie was found.
    if (decodedCookies.length < 2) {
        return "";
    }

    let mediatheekCookie = decodedCookies[1].split("; expires=");
    return mediatheekCookie;
}

/**
 * Revokes the access token on google, and removes the cookie and temporary accessToken state.
 *
 * @param {string} accessToken The access token to be revoked.
 * @param {function} setAccessToken The function that alters the accessToken state.
 */
function revokeAccess(accessToken, setAccessToken) {
    let postData = "token=" + accessToken[0];
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "https://accounts.google.com/o/oauth2/revoke");
    xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");

    xhr.onload = () => {
        console.log(xhr.response);
    };

    xhr.send(postData);

    deleteCookie();
    setAccessToken("");
}

/**
 * Remove the mediatheekAccess cookie from the cookies.
 */
function deleteCookie() {
    if (getAccessCookie() === "") {
        return;
    }

    document.cookie = "mediatheekAccess=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

/**
 * Component for the authorization and logout buttons.
 *
 * @param {string} name Name that should be displayed in the button.
 * @param {boolean} auth Whether the button authorizes or revokes authorization.
 * @param {boolean} accessToken The access token with which authorization is tested and which can be revoked on logout
 *                              click.
 * @param {function} setAccessToken The function with which the access token state is altered.
 * @param {string} className Optional additional classnames to add to the button.
 */
export function AuthButton({ name, auth, accessToken, setAccessToken, className }) {
    const [err, setErr] = useState(false);

    let disabled = auth ? !!accessToken : !accessToken;

    // Create form with necessary data to redirect user to google login page.
    let onClick = () => {
        var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

        var form = document.createElement("form");
        form.setAttribute("method", "GET");
        form.setAttribute("action", oauth2Endpoint);

        var params = {
            client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
            redirect_uri: "http://slurpvissen.online/auth?",
            response_type: "token",
            scope: SCOPES,
            include_granted_scopes: "true",
            state: "pass-through value",
        };

        for (var p in params) {
            var input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", p);
            input.setAttribute("value", params[p]);
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
    };

    // If not for authorization, make button for revoking access token.
    if (!auth) {
        onClick = () => revokeAccess(accessToken, setAccessToken);
    }

    return (
        <div
            className={`d-inline-block`}
            onClick={() => {
                if (disabled) {
                    setErr(true);
                }
            }}
            onAnimationEnd={() => {
                setErr(false);
            }}>
            <button
                type="button"
                className={`${className} btn btn-outline-primary me-2 ${err ? "err" : ""}`}
                onClick={onClick}
                disabled={disabled}>
                {name}
            </button>
        </div>
    );
}
