/**
 * File:            OpenedMedia.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Overlay for displaying Media when it is opened.
 */

/**
 * General overlay for displayed opened Media. Darkens background and displays a enlarged version of the media.
 *
 * @param {int} openedMedia The index of the media that should be opened.
 * @param {function} setOpenedMedia The function that alters the openedMedia state.
 * @param {array} mediaList The list in which the Media are stored.
 * @param {array} order The order in which the Media are displayed on the page.
 */
export function OpenedMedia({ openedMedia, setOpenedMedia, mediaList, order }) {
    let index = order[openedMedia];

    // Hide overlay based on if the source is set or not.
    let disp = openedMedia !== -1 ? "" : " d-none";
    if (disp === "") {
        document.body.classList.add("overflow-hidden");
    } else {
        document.body.classList.remove("overflow-hidden");
    }
    if (!mediaList[index]) return;

    let file = mediaList[index];
    let elm;
    if (file["type"].startsWith("image")) {
        elm = <img className="h-100" src={file["content"]} alt=""></img>;
    } else if (file["type"].startsWith("video")) {
        elm = (
            <video controls className="h-100">
                <source type="video/mp4" src={file["content"]} />
            </video>
        );
    }

    // Remove the back button if the opened media is the first in the list.
    let backElm = (
        <div
            className="position-absolute top-50 start-0"
            onClick={() => {
                setOpenedMedia(openedMedia - 1);
            }}>
            <i className="bi bi-arrow-left-circle-fill h1 openedmedia-icon" fill="white"></i>
        </div>
    );
    if (index === 0) {
        backElm = null;
    }

    // Remove the next button if the opened media is the last in the list.
    let nextElm = (
        <div
            className="position-absolute top-50 end-0"
            onClick={() => {
                setOpenedMedia(openedMedia + 1);
            }}>
            <i className="bi bi-arrow-right-circle-fill h1 openedmedia-icon" fill="white"></i>
        </div>
    );
    if (index === mediaList.length - 1) {
        nextElm = null;
    }

    return (
        <div
            className={
                "openedmedia position-fixed top-0 start-0 w-100 h-100 bg-secondary overflow-hidden user-select-none " +
                disp
            }
            style={{ "--bs-bg-opacity": 0.5 }}>
            <div className="position-absolute top-0 w-100 mb-1 text-center">
                <h1>{mediaList[openedMedia] ? mediaList[index]["name"] : ""}</h1>
                <div
                    className="position-absolute end-0"
                    onClick={() => {
                        setOpenedMedia(-1);
                    }}>
                    <i className="bi bi-x-circle-fill h1 openedmedia-icon" fill="white"></i>
                </div>
            </div>

            {backElm}

            <div className="position-absolute top-50 start-50 img-wrapper translate-middle">{elm}</div>

            {nextElm}
        </div>
    );
}
