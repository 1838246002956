/**
 * File:            OpenedMedia.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Component for the Random page, shows a random Media out the selected folders.
 */
import { useEffect, useState } from "react";
import { get_files, get_file_data } from "../FileManager.js";

import { Media } from "./Media.js";

const checkNames = {
    Dylan_checkbox: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_DYLAN_ID,
    Ivar_checkbox: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_IVAR_ID,
    Mark_checkbox: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARK_ID,
    Martijn_checkbox: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARTIJN_ID,
    Troy_checkbox: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TROY_ID,
    Tjappies_checkbox: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TJAPPIES_ID,
};

/**
 * Component for the Random page, shows a random Media out folders selected by checkboxes. Opens an OpenedMedia instance
 * when the random button is clicked.
 */
export function Random() {
    const [randomMedia, setRandomMedia] = useState();
    const [selected, setSelected] = useState([]);
    const [files, setFiles] = useState({});

    if (Object.keys(files).length === 0) {
        let initFiles = {};
        for (let val of Object.values(checkNames)) {
            initFiles[val] = [];
        }
        setFiles(initFiles);
    }

    useEffect(() => {
        updateLoaded(files, setFiles, selected);
    }, [selected]);

    let checkboxes = [];
    for (let [item, folderId] of Object.entries(checkNames)) {
        // Event listener for check
        let onCheck = function (e) {
            let check = e.target.checked;

            if (check) {
                setSelected(selected.concat([folderId]));
            } else {
                let index = selected.indexOf(folderId);
                if (index !== -1) {
                    setSelected(selected.toSpliced(index, 1));
                }
            }
        };

        let checkbox = (
            <div key={item} className="form-check">
                <label className="form-label" htmlFor={item}>
                    {item.split("_")[0]}
                </label>
                <input
                    className="form-check-input form-check-block"
                    id={item}
                    name={item}
                    type="checkbox"
                    onChange={onCheck}
                />
            </div>
        );

        checkboxes.push(checkbox);
    }

    return (
        <>
            <div id="main-page" className="d-flex flex-row align-items-center overflow-hidden">
                <div className="mx-5 form-group">{checkboxes}</div>
                <div>
                    <button
                        type="button"
                        className="w-100 btn btn-primary my-1"
                        onClick={() => {
                            includeAll(setSelected);
                        }}>
                        Include all
                    </button>
                    <button
                        type="button"
                        className="w-100 btn btn-primary my-1"
                        onClick={() => {
                            includeNone(setSelected);
                        }}>
                        Include none
                    </button>
                    <GenButton selected={selected} setRandomMedia={setRandomMedia} files={files} />
                </div>
                {randomMedia}
            </div>
        </>
    );
}

/**
 * Opens a random image next to the selection form.
 *
 * @param {function} setRandomMedia Function that alters the randomMedia state.
 * @param {Array} selected The array of the selected folder IDs.
 * @param {Object} files The object that matches files with folder IDs.
 */
function openRandom(setRandomMedia, selected, files) {
    if (Object.keys(files).length === 0) {
        return;
    }

    let mediaList = [];
    selected.forEach(function (folderId, _) {
        if (folderId in files) {
            mediaList.push(...files[folderId]);
        }
    });

    if (mediaList.length === 0) {
        return;
    }

    let randomIndex = Math.floor(Math.random() * mediaList.length);
    let randComp = <Media className="h-95" file={mediaList[randomIndex]} />;
    setRandomMedia(randComp);
}

/**
 * Updates the loaded array when a checkbox is pressed.
 *
 * @param {Object} files The object that matches files with folder IDs.
 * @param {function} setFiles Function that alters the files state.
 * @param {Array} selected The array of the selected folder IDs.
 */
function updateLoaded(files, setFiles, selected) {
    selected.forEach(function (folderId) {
        get_files(folderId)
            .then(function (data) {
                // If all files are already present in the files object, do nothing.
                if (files[folderId].length >= data.length) {
                    return;
                }

                data.forEach(function (file) {
                    if (contains(files[folderId], file["id"])) {
                        return;
                    }

                    get_file_data(file["id"])
                        .then(function (data) {
                            setFiles((files) => {
                                return { ...files, [folderId]: files[folderId].concat([data]) };
                            });
                        })
                        .catch(function (e) {
                            console.log(e);
                        });
                });
            })
            .catch(function (e) {
                console.error(e);
            });
    });
}

/**
 * Compares the IDs of all elements in array with the ID of elm to check if an instance of elm already exists in array.
 *
 * @param {Array} array The array to iterate over.
 * @param {string} id The id to search in the array.
 * @returns True if elm is found in array, false otherwise.
 */
function contains(array, id) {
    let found = false;
    array.forEach((element) => {
        if (element["id"] === id) found = true;
    });

    return found;
}

/**
 * Selects all checkboxes. Should also update the loaded list, but this currently does not work, is therefore
 * temporarily disabled.
 *
 * @param {function} setSelected Function that alters the selected state.
 */
function includeAll(setSelected) {
    Object.keys(checkNames).forEach(function (chkbx, _) {
        let elm = document.getElementById(chkbx);
        elm.checked = true;
    });

    setSelected(Object.values(checkNames));
}

/**
 * Deselects all checkboxes. Should also update the loaded list, but this currently does not work, is therefore
 * temporarily disabled.
 *
 * @param {function} setSelected Function that alters the selected state.
 */
function includeNone(setSelected) {
    Object.keys(checkNames).forEach(function (chkbx, _) {
        let elm = document.getElementById(chkbx);
        elm.checked = false;
    });

    setSelected([]);
}

/**
 * Button component that show a new random image when clicked.
 *
 * @param {Array} selected The array of selected folder IDs.
 * @param {function} setRandomMedia Function that alters the randomMedia state.
 * @param {Object} files The object that matches files with folder IDs.
 */
function GenButton({ selected, setRandomMedia, files }) {
    const [err, setErr] = useState(false);
    let disabled = selected.length === 0 ? true : false;

    return (
        <div
            onClick={() => {
                if (disabled) {
                    setErr(true);
                }
            }}>
            <button
                type="button"
                className={`w-100 btn btn-primary my-1 ${err ? "err" : ""}`}
                onClick={() => {
                    openRandom(setRandomMedia, selected, files);
                }}
                onAnimationEnd={() => {
                    setErr(false);
                }}
                disabled={disabled}>
                (Re)generate Random
            </button>
        </div>
    );
}
