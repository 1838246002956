/**
 * File:            Header.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Header component, contains the name, navigation buttons and authorization buttons.
 */
import { Link } from "react-router-dom";
import { useState } from "react";

import { AuthButton } from "../auth.js";

/**
 * Main header object, a sticky div with three segments, information (name and logo), navigation buttons for all pages,
 * and the authorization and logout buttons.
 *
 * @param {string} accessToken The token with which data can be gathered from the Google API.
 * @param {function} setAccessToken The function with which the accessToken state can be altered.
 * @param {Object} userInfo An object with various information string about the logged user.
 */
export function Header({ accessToken, setAccessToken, userInfo }) {
    let title = "Slurpvissen Mediatheek";
    let authorized = !!accessToken;

    return (
        <nav className="navbar py-0 navbar-expand-lg navbar-light border-bottom bg-light" id="header">
            <div className="container-fluid h-100">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#mediatheekNavbarToggler"
                    aria-controls="mediatheekNavbarToggler"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="h-100">
                    <img className="h-100" src={process.env.PUBLIC_URL + "slurpvis.webp"} alt=""></img>

                    {/* <p className="navbar-brand" id="header-title">
                        {title}
                    </p> */}
                </div>
                <div className="z-3 p-2 collapse bg-light navbar-collapse" id="mediatheekNavbarToggler">
                    <ul className="navbar-nav me-auto justify-content-center mb-md-0">
                        <PageLink name="Home" href="/" />
                        <PageLink
                            name="Main"
                            href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_ID}
                            authorized={authorized}
                        />
                        <PageLink
                            name="Dylan"
                            href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_DYLAN_ID}
                            authorized={authorized}
                        />
                        <PageLink
                            name="Ivar"
                            href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_IVAR_ID}
                            authorized={authorized}
                        />
                        <PageLink
                            name="Mark"
                            href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARK_ID}
                            authorized={authorized}
                        />
                        <PageLink
                            name="Martijn"
                            href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARTIJN_ID}
                            authorized={authorized}
                        />
                        <PageLink
                            name="Troy"
                            href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TROY_ID}
                            authorized={authorized}
                        />
                        <PageLink
                            name="Tjappies"
                            href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TJAPPIES_ID}
                            authorized={authorized}
                        />
                        <PageLink name="Random" href="/random" authorized={authorized} />
                    </ul>
                    <div className="d-flex">
                        <div className="justify-items-center">
                            {authorized ? "Authorized as: " + userInfo.displayName : "Not logged in."}
                        </div>
                        <div>
                            <AuthButton name="Authorize" auth={true} accessToken={accessToken} />
                            <AuthButton
                                name="Logout"
                                auth={false}
                                accessToken={accessToken}
                                setAccessToken={setAccessToken}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        // <header className="hidden-xs d-flex container bg-white mb-1 mx-5 align-items-center border-bottom" id="header">
        //     <div className="col-3 mb-2 mb-md-0 h-100">
        //         <div className="h-100 user-select-none" id="header-img">
        //             <img className="h-100" src={process.env.PUBLIC_URL + "slurpvis.webp"} alt=""></img>
        //         </div>
        //         <div className="h-100">
        //             <h1 className="h-100 d-flex align-items-center" id="header-title">
        //                 {title}
        //             </h1>
        //         </div>
        //     </div>
        //     <ul className="nav col-6 mb-2 justify-content-center mb-md-0">
        //         <PageLink name="Home" href="/" />
        //         <PageLink
        //             name="Ivar"
        //             href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_IVAR_ID}
        //             authorized={authorized}
        //         />
        //         <PageLink
        //             name="Mark"
        //             href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARK_ID}
        //             authorized={authorized}
        //         />
        //         <PageLink
        //             name="Martijn"
        //             href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARTIJN_ID}
        //             authorized={authorized}
        //         />
        //         <PageLink
        //             name="Troy"
        //             href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TROY_ID}
        //             authorized={authorized}
        //         />
        //         <PageLink
        //             name="Tjappies"
        //             href={"/page?id=" + process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TJAPPIES_ID}
        //             authorized={authorized}
        //         />
        //         <PageLink name="Random" href="/random" authorized={authorized} />
        //     </ul>
        //     <div className="col-3 text-end">
        //         <div className="justify-items-center">
        //             {authorized ? "Logged in as: " + userInfo.displayName : "Not logged in."}
        //         </div>
        //         <div>
        //             <AuthButton name="Authorize" auth={true} accessToken={accessToken} />
        //             <AuthButton name="Logout" auth={false} accessToken={accessToken} setAccessToken={setAccessToken} />
        //         </div>
        //     </div>
        // </header>
    );
}

/**
 * Link that directs the user to each of the different pages.
 *
 * @param {string} name The name that should be displayed as the Link.
 * @param {string} href The target location to link to.
 * @param {boolean} authorized Whether the user is authorized by the API.
 */
function PageLink({ name, href, authorized = true }) {
    const [err, setErr] = useState(false);
    let classes = authorized ? "" : "disabled";
    classes += err ? " err" : "";

    return (
        <li
            onClick={() => {
                if (!authorized) setErr(true);
            }}
            onAnimationEnd={() => {
                setErr(false);
            }}
            className={`nav-item ${classes}`}>
            <Link to={authorized ? href : ""} className="nav-link px-2">
                {name}
            </Link>
        </li>
    );
}
